(function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('ScrollableList', ['Hammer'], factory);
    } else {
        // Browser globals
        root.ScrollableList = factory(root.Hammer);
    }
}(this, function (Hammer) {
    'use strict';

    /**
     * @private
     * @description Default options
     */
    var DEFAULTS = {
        pager: false
    }

    /**
     * @private
     * @function createButtons
     * @description create navigation buttons
     * @returns {undefined} 
     */
    function createButtons(prev, next) {
        if (!prev) {
            prev = document.createElement('a');
            prev.classList.add('prevbutton');
            prev.setAttribute('data-prev-item', '');
            this.container.appendChild(prev);
            prev.addEventListener('click', this.toright);
        }
        if (!next) {
            next = document.createElement('a');
            next.classList.add('nextbutton');
            next.setAttribute('data-next-item', '');
            this.container.appendChild(next);
            next.addEventListener('click', this.toleft);
        }
        return;
    }

    /**
     * @private
     * @function destroyButtons
     * @description destroy list buttons
     * @returns {undefined}
     */
    function destroyButtons(prev, next) {
        if (prev) {
            prev.addEventListener('click', this.prev);
            prev.remove();
        }
        if (next) {
            next.addEventListener('click', this.next);
            next.remove();
        }
        delete this.container.dataset.buttons;
    }

    /**
 * @private
 * @function createPager
 * @description Create pager navigation
 * @returns {undefined}
 */
    function createPager(pagerElement) {
        if (this.pager) return;
        if (!pagerElement) {
            pagerElement = document.createElement('div');
            pagerElement.classList.add('listpager');
        }
        var pagerList = pagerElement.querySelector('ul')
        if (!pagerList) {
            pagerList = document.createElement('ul')
        }
        pagerElement.appendChild(pagerList);
        this.pager = new Pager(pagerElement, this);
        this.container.appendChild(pagerElement);
    }

    /**
     * @private
     * @function destroyPager
     * @description Destroy pager navigation
     * @returns {undefined}
     */
    function destroyPager() {
        this.pager.destroy();
        this.pager = null;
        var pagerElement = this.container.querySelector('.listpager');
        pagerElement.remove();
    }



    /**
     * @class ScrollableList
     * @description Lista scrollabile di elementi se contiene più elementi della sua larghezza
     * @param {HTMLElement} element Container element
     * @param {Object} options Configuration
     */
    function ScrollableList(element, options) {

        Object.defineProperties(this, {
            defaults: {
                value: {}
            },
            container: {
                value: element
            },
            items: {
                value: element.querySelectorAll('.listitem')
            },
            step: {
                value: 0,
                writable: true
            },
            strip: {
                value: 0,
                writable: true
            },
            hammer: {
                value: (typeof (Hammer) !== 'undefined') ? new Hammer(element) : null
            },
            setup: {
                value: this.setup.bind(this)
            },
            update: {
                value: this.update.bind(this)
            },
            toleft: {
                value: this.toleft.bind(this)
            },
            toright: {
                value: this.toright.bind(this)
            },
            panstart: {
                value: this.panstart.bind(this)
            },
            panleft: {
                value: this.panleft.bind(this)
            },
            panright: {
                value: this.panright.bind(this)
            },
            panend: {
                value: this.panend.bind(this)
            },
            position: {
                value: this.position.bind(this)
            },
            destroy: {
                value: this.destroy.bind(this)
            }
        });

        createButtons.apply(this);

        this.setup(options);
        this.update();

        if (this.hammer) {
            this.hammer.get('swipe').set({
                threshold: parseInt(this.step * .1),
                velocity: 0.2
            });
            this.hammer.get('press').set({ time: 100 })
            this.hammer.get('pan').set({ threshold: parseInt(this.step * .25), });
            this.hammer.on('swipeleft', this.toleft);
            this.hammer.on('swiperight', this.toright);
            this.hammer.on('press', this.panstart);
            this.hammer.on('panleft', this.panleft);
            this.hammer.on('panright', this.panright);
            this.hammer.on('panend', this.panend);
            this.container.addEventListener('mouseup', this.panend);
        }
        window.addEventListener('resize', this.update);

        this.dispatch('list-ready', {
            bubbles: true,
            cancelable: false
        });
    }

    ScrollableList.prototype = {
        /**
         * @function ScrollableList.setup
         * @description Configure instance
         * @param {Object} options Configuration
         */
        setup: function (options) {
            for (var opt in DEFAULTS) {
                this.defaults[opt] = (typeof (this.defaults[opt]) !== 'undefined') ? this.defaults[opt] : DEFAULTS[opt];
                if (typeof (this.container.dataset[opt]) !== 'undefined') this.defaults[opt] = this.container.dataset[opt];
                if (options && typeof (options[opt]) !== 'undefined') this.defaults[opt] = options[opt];
                this.container.dataset[opt] = this.defaults[opt];
            }
        },
        update: function () {
            if (this.items.length) {
                this.step = this.items[0].clientWidth;
                this.strip = this.step * this.items.length;
                this.viewport = Math.round(this.container.clientWidth / this.step);
            }

            if (this.viewport >= this.items.length) {
                this.container.classList.add('-hide-controls');
            } else {
                this.container.classList.remove('-hide-controls');
            }

            this.container.querySelector('ul').style.transform = 'translateX(0px)';

            // add/remove pager
            if (this.defaults.pager) {
                if(this.pager) destroyPager.apply(this);
                var pagerElement = this.container.querySelector('.listpager');
                createPager.apply(this, [pagerElement]);
            } else {
                if (this.pager) destroyPager.apply(this);
            }
        },
        toleft: function () {
            if (this.container.classList.contains('-panning')) return;
            var x = Math.abs(this.position());
            if (x / this.step + this.viewport >= this.items.length - this.viewport) {
                var toX = (this.items.length - this.viewport) * this.step * -1;
                if (toX == this.position()) return;
                this.position(toX);
                this.dispatch('list-scroll', {
                    bubbles: true,
                    cancelable: false,
                    detail: {
                        action: 'toleft',
                        viewportIndex: Math.ceil(Math.abs(toX / this.step / this.viewport)),
                        viewportSize: this.viewport,
                        listSize: this.items.length
                    }
                });
                return;
            }
            var toX = (x / this.step + this.viewport) * this.step * -1;
            this.dispatch('list-scroll', {
                bubbles: true,
                cancelable: false,
                detail: {
                    action: 'toleft',
                    viewportIndex: Math.ceil(Math.abs(toX / this.step / this.viewport)),
                    viewportSize: this.viewport,
                    listSize: this.items.length
                }
            });
            this.position(toX);
        },
        toright: function () {
            if (this.container.classList.contains('-panning')) return;
            var x = Math.abs(this.position());
            if (x < this.step) return;
            var toX = Math.min((x - this.step * this.viewport) * -1, 0);
            this.dispatch('list-scroll', {
                bubbles: true,
                cancelable: false,
                detail: {
                    action: 'toright',
                    viewportIndex: Math.ceil(Math.abs(toX / this.step / this.viewport)),
                    viewportSize: this.viewport,
                    listSize: this.items.length
                }
            });
            this.position(toX);
        },
        panstart: function (event) {
            this.container.classList.add('-panning');
            this.panDistance = 0;
        },
        panleft: function (event) {
            if (!this.container.classList.contains('-panning')) return;
            this.panDistance = Math.abs(parseInt(Math.abs(event.deltaX) * Math.abs(event.velocity)) - this.panDistance);
            var x = Math.abs(this.position());
            var max = this.step * this.items.length - this.container.clientWidth;
            var move = Math.max(x + this.panDistance, x);
            var move = Math.min(x + this.panDistance, max);
            this.position(move * -1);
        },
        panright: function (event) {
            if (!this.container.classList.contains('-panning')) return;
            this.panDistance = parseInt(Math.abs(event.deltaX) * Math.abs(event.velocity)) - this.panDistance;
            var x = Math.abs(this.position());
            var max = this.step * this.items.length - this.container.clientWidth;
            var move = Math.min(x - this.panDistance, max);
            move = Math.max(x - this.panDistance, 0);
            this.position(move * -1);
        },
        panend: function (event) {
            this.container.classList.remove('-panning');
            this.panDistance = 0;
            var x = this.position();
            var left = Math.round(x / this.step) * this.step;
            this.position(left);
        },
        position: function (position) {
            var element = this.container.querySelector('ul');
            if (isNaN(position)) {
                var x = element.style.transform.match(/translateX\(\-?[0-9]+(px)\)/);
                return x ? parseInt(x[0].replace('translateX(', '').replace(')', '')) : 0;
            } else {
                position = Math.min(position, 0);
                element.style.transform = 'translateX(' + position + 'px)';
                return position;
            }
        },
        goto: function (index) {
            if (isNaN(index)) index = 0;
            index = Math.abs(index);
            var position = this.step * this.viewport * index * -1;
            position = Math.min(position, 0);
            var max = (this.items.length - this.viewport) * this.step * -1
            position = Math.max(position, max);
            if (position == this.position()) return this.position();
            this.dispatch('list-scroll', {
                bubbles: true,
                cancelable: false,
                detail: {
                    action: 'goto',
                    viewportIndex: index,
                    viewportSize: this.viewport,
                    listSize: this.items.length
                }
            });
            return this.position(position);
        },
        ready: function() {
            return this.container && this.items.length
        },
        destroy: function () {
            this.hammer.off('swipeleft');
            this.hammer.off('swiperight');
            this.hammer.off('press');
            this.hammer.off('panleft');
            this.hammer.off('panright');
            this.hammer.off('panend');
            this.container.removeEventListener('mouseup', this.panend);
            window.removeEventListener('resize', this.update);
            this.container.querySelector('ul').style.transform = null;
            destroyButtons.apply(this);
            if (this.pager) destroyPager.apply(this);
        },
        dispatch: function (name, init) {
            var newEvent;
            try {
                newEvent = new CustomEvent(name, init)
            } catch (err) {
                newEvent = document.createEvent('CustomEvent')
                newEvent.initCustomEvent(
                    name,
                    init && typeof (init.bubbles) !== 'undefined' ? init.bubbles : true,
                    init && typeof (init.cancelable) !== 'undefined' ? init.cancelable : false,
                    init ? init.detail : null
                );
            }
            this.container.dispatchEvent(newEvent);
        }
    }

    /**
     * @class Pager
     * @param {HTMLElement} element
     * @param {ScrollableList} list
     */
    function Pager(element, list) {
        Object.defineProperties(this, {
            container: {
                value: element
            },
            list: {
                value: list
            },
            items: {
                value: null,
                writable: true
            },
            setup: {
                value: this.setup.bind(this)
            },
            update: {
                value: this.update.bind(this)
            },
            goto: {
                value: this.goto.bind(this)
            }
        });

        if (this.list.ready()) {
            this.setup();
        } else {
            this.list.container.addEventListener('list-ready', this.setup);
        }
    }

    Pager.prototype = {
        setup: function () {
            this.items = null;
            var container = this.container;
            this.container.querySelector('ul').innerHTML = '';
            var max = Math.ceil(this.list.items.length / this.list.viewport);
            for (var i = 0; i < max; i++) {
                var item = document.createElement('li');
                item.classList.add('pageritem');
                item.dataset.slideIndex = i;
                item.innerHTML = i + 1;
                this.container.querySelector('ul').appendChild(item);
                item.addEventListener('click', this.goto)
            }
            this.items = this.container.querySelectorAll('.pageritem[data-slide-index]');
            this.list.container.addEventListener('list-scroll', this.update);
            this.update();
        },
        update: function (event) {
            // remove "current" state from current pager item
            var current = this.container.querySelector('.pageritem.-current');
            if (current) current.classList.remove('-current');
            var index = 0;
            if (event) index = event.detail.viewportIndex;
            // get current slide
            index = (index < this.items.length) ? index : this.items.length;
            if (this.container.querySelector('.pageritem[data-slide-index="' + index + '"]')) {
                this.container.querySelector('.pageritem[data-slide-index="' + index + '"]').classList.add('-current');
            }
        },
        goto: function (event) {
            var index = parseInt(event.target.dataset.slideIndex);
            if (!isNaN(index)) this.list.goto(index);
        },
        destroy: function () {
            this.list.container.removeEventListener('list-slide-start', this.update);
            this.list.container.removeEventListener('list-ready', this.setup);
            Array.prototype.forEach.apply(this.items, [function (item) {
                item.removeEventListener('click', this.goto);
            }, this]);
        }
    }

    return ScrollableList

}));
