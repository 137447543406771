(function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('YTPlaylist', [], factory);
    } else {
        // Browser globals
        root.YTPlaylist = factory();
    }
}(this, function () {
  'use strict';

  function loadAPI() {
    if(window.YT && YT.loaded) return createPlayer.apply(this);
    var tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    window.onYouTubeIframeAPIReady = createPlayer.bind(this);
  }

  function createPlayer() {
    this.videos[0].parentElement.classList.add('-current');
    var videoId = this.videos[0].getAttribute('data-video-id');
    this.player = new YT.Player(this.playerElement, {
      videoId: videoId,
      playerVars: {
        modestbranding: 1,
        rel: 0
      }
    });
  }

  function YTPlaylist(element) {

    Object.defineProperties(this, {
      container: {
        value: element
      },
      playerElement: {
        value: element.querySelector('.videoplayer'),
      },
      videos: {
        value: element.querySelectorAll('[data-video-id]')
      },
      play: {
        value: this.play.bind(this)
      }
    });
    loadAPI.apply(this);
    Array.prototype.forEach.apply(this.videos, [function(item) { item.addEventListener('click', this.play) }, this]);
  }

  YTPlaylist.prototype = {
    container: null,
    play: function(event) {
      event.preventDefault();
      if(!this.player) return;
      if(typeof(this.player.playVideo) !== 'function') return;
      var el = event.target;
      var videoid = el.getAttribute('data-video-id')
      while(!videoid) {
        el = el.parentElement;
        videoid = el.getAttribute('data-video-id');
      }
      window.scrollTo(0,0);
      this.player.cueVideoById(videoid);
      this.player.playVideo();
      this.container.querySelector('.videothumb.-current').classList.remove('-current');
      el.parentElement.classList.add('-current');
    }
  }

  return YTPlaylist;
  
}));