(function (root, factory) {
    // UMD/AMDWeb module bolierplate: https://github.com/umdjs/umd/blob/master/templates/amdWeb.js
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define('ShareMenu', [], factory);
    } else {
        // Browser globals
        root.ShareMenu = factory();
    }
}(this, function () {
  'use strict';

  var DEFAULTS = {
      media: {}
  }

  function ShareMenu(element, options) {
    Object.defineProperties(this, {
        defaults: {
            value: {}
        },
        container: {
            value: element
        },
        buttons: {
            value: element.querySelectorAll('.sharebutton')
        },
        menu: {
            value: element.querySelector('.sharebuttons')
        },
        setup: {
            value: this.setup.bind(this)
        },
        show: {
            value: this.show.bind(this)
        },
        hide: {
            value: this.hide.bind(this)
        },
        share: {
            value: this.share.bind(this)
        }
    });

    this.setup(options);

    window.addthis.addEventListener('addthis.menu.share', this.share);

    Array.prototype.forEach.apply(this.buttons, [function(el) {
        el.addEventListener('click', this.show);
    }, this]);

    document.addEventListener('click', this.hide);

    this.menu.addEventListener('transitionend', function(event) {
        if(event.target == this && !event.target.classList.contains('-visible')) {
            delete this.dataset.current;
        }
    })
  }

  ShareMenu.prototype = {
      container: null,
      buttons: null,
      setup: function(options) {
        for(var opt in DEFAULTS) {
            this.defaults[opt] = (typeof(this.defaults[opt]) !== 'undefined') ? this.defaults[opt] : DEFAULTS[opt];
            if (typeof(this.container.dataset[opt]) !== 'undefined') this.defaults[opt] = this.container.dataset[opt];
            if (options && typeof(options[opt]) !== 'undefined') this.defaults[opt] = options[opt];
        }
      },
      show: function(event) {
        event.preventDefault();
        event.stopPropagation();
        this.menu.classList.add('-visible');
        this.current = event.target.dataset.button
        this.menu.dataset.current = this.current;
        this.message = event.target.dataset.message;
        addthis_share = {
            url: window.location.href,
            title: this.message,
            media: this.defaults.media[this.current]
        }
      },
      hide: function(event) {
        this.current = null;
        this.message = null;
        this.menu.classList.remove('-visible');
      },
      share: function(event) {

      }
  }

  return ShareMenu;

}));